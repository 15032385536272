@import "../../assets/scss/theme.scss";

table{
    tbody{
        background-color: #fff !important;
    }
}
  
  .calendar-container {
    min-height: 350px;
    background: #eee;
  }
  
  .custom-calendar {
    border: none !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  

// .apexcharts-canvas {
//   text {
//     fill: $text-muted !important;
//   }
// }
